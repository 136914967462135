<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">考试查询</span>
        <!-- 面包屑 -->
        <div class="crumbs">
          <router-link class="crumbs_item" tag="a" to="/admin/Search/Templatepage">配置证书模板 / </router-link>
          <span class="crumbs_item crumbs_last">编辑</span>
        </div>
      </div>
    </div>
    <!-- 表格列表 -->
    <div class="table-box">
      <p class="name">证书名称：{{$route.query.name}}</p>
      <div class="boxitem">
        <span class="title">高度调整（单位：磅）</span>
        <div class="element">
          <div class="left" v-for="(item,index) in formList" :key="index">
            【{{item.name}}】 高度：
            <a-input-number
              :disabled="isDisabled"
              id="inputNumber"
              v-model="item.value"
              :min="0"
            />
            <!-- <a-icon class="del" slot="suffixIcon" type="delete"/> -->
          </div>
        </div>
      </div>
      <div class="boxitem">
        <span class="title">左边距调整（单位：厘米）<a @click="tipsVisible = true">调整提示 <a-icon type="question-circle" /></a></span>
        <div class="element">
          <div class="left">
            第一页整体左边距：
            <a-input-number
              :disabled="isDisabled"
              id="inputNumber"
              v-model="firstLeft"
              :min="0"
            />
          </div>
          <div class="left">
            第二页整体左边距：
            <a-input-number
              :disabled="isDisabled"
              id="inputNumber"
              v-model="secondLeft"
              :min="0"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="button">
      <a-button class="btn" @click="$router.go(-1)">取消</a-button>
      <a-button type="primary" v-if="isDisabled" @click="isDisabled = false" :loading="loading" class="btn">修改</a-button>
      <a-button type="primary" v-else @click="onSavaForm()" :loading="loading" class="btn">确认</a-button>
    </div>
    <a-modal
      v-model="tipsVisible"
      width="641px"
      title="调整提示"
      :centered="true"
      :closable="false"
    >
      <p style="margin-bottom:10px;font-size:18px">页面字段左右移动调整为当前页面中所有变量字段的整体移动</p>
      <img class="example-img" src="@/assets/image/Example.jpg" alt="">
      <template #footer></template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return { 
      isDisabled: true,
      value: 3,
      loading:false,
      tipsVisible:false,
      // formList:[ // 行原生配置
        // {name: '证书编号', value: 0},
        // {name: '出生日期', value: 0},
        // {name: '发证年份', value: 0},
        // {name: '身份证号', value: 0},
        // {name: '发证月份', value: 0},
        // {name: '学历', value: 0},
        // {name: '姓名', value: 0},
        // {name: '职务职称', value: 0},
        // {name: '性别', value: 0},
        // {name: '工作单位', value: 0},
      // ]
      formList:[], // 行位置配置
      firstLeft: 0, //第一页整体左边距
      secondLeft: 0, //第二页整体左边距
      width:'', // 页边距
    };
  },
  // 事件处理器
  methods: {
    // 获取数据
    getData() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/export-temp-config/detail",
        method: "get",
        params: {
          id: this.$route.query.id,
        },
      }).then((res) => {
        this.loading = false;
        if(res.code == 200 && res.success){
          if(res.data.height){
            this.formList = JSON.parse(res.data.height)
          }
          if(res.data.width){
            this.width = res.data.width.split("#")
            this.firstLeft = this.width[0]
            this.secondLeft = this.width[2]
          }
        }
      });
    },
    onSavaForm(){
      // 重置左边距值  只修改数组位置的第1个和第3个
      this.width[0] = this.firstLeft
      this.width[2] = this.secondLeft
      this.loading = true;
      this.isDisabled = true
      this.$ajax({
        url: "/hxclass-management/export-temp-config/edit",
        method: "PUT",
        params: {
          id: this.$route.query.id,
          height: JSON.stringify(this.formList),
          width: this.width.join('#'),
        },
      }).then((res) => {
        this.loading = false;
        this.isDisabled = false
        if(res.code == 200 && res.success){
          this.$message.success('编辑成功');
          this.$router.go(-1);
        } else {
          this.isDisabled = false
          this.$message.error(res.message);
        }
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 获取数据
    this.getData();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  .name {
    font-size: 16px;
    margin: 10px 0;
    line-height: 30px;
  }
  .title {
    font-size: 16px;
    display: block;
    font-weight: 600;
    margin-bottom: 16px;
    line-height: 30px;
    a{
      font-size: 12px;
    }
  }
  .boxitem {
    .element {
      width: 325px;
      overflow: hidden;
      .left{
        float: left;
        width: 325px;
        margin-bottom: 16px;
        text-align: right;
        .del{
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
.button{
  margin: 40px 0;
}
.example-img{
  width: 100%;
}
</style>
